import Api from './Api'

export default {
    login(credentials) {
        return Api().post('auth/login', credentials);
    },
    getUser(){
        return Api().get('auth/profile');
    },
    create(formData){
        return Api().post('entidades/register', formData);
    },
    update(data){
        return Api().patch(`entidades/update/${data.id}`, data);
    },
    newPassVerifyEmail(userInput){
        return Api().post(`auth/newPassword/verify-user`, userInput );
    },
    newPassVerifyToken(token){
        return Api().post(`auth/newPassword/verify-token`, token);
    },
    newPass(credentials){
        return Api().post(`auth/newPassword`, credentials );
    },
    confirmUser(credentials){
        return Api().get(`auth/confirmAcount/${credentials}`,);
    },
}