import EntidadeService from '../../services/EntidadeService'

const state = () => ({
    loadingEntidades: false,
    entidades: [],
    entidade: null,
    total: 0,
    pages: 0,
    page: 1,
    totalEntidades: null,
    show_modal_filter_entidade: false,
    filters_entidade: [
        {
            id: 'status',
            name: 'Status',
            type: 'select',
            isOpen: false,
            options: [
                { value: 'ativa', label: 'Ativa', checked: false },
                { value: 'inativa', label: 'Inativa', checked: false },
                { value: 'pendente', label: 'Pendente', checked: false },
                { value: 'banida', label: 'Banida', checked: false }
            ],
        },
        {
            id: 'qty_events',
            name: 'Quantidade de Eventos',
            type: 'select',
            isOpen: false,
            options: [
                { value: '0', label: '0', checked: false },
                { value: '1', label: '1', checked: false },
                { value: '2', label: '2', checked: false },
                { value: '3', label: '3', checked: false }
            ],
        },
        {
            id: 'pos_evento',
            name: 'Postagens Pós Eventos',
            type: 'select',
            isOpen: false,
            options: [
                { value: 'true', label: 'Realizou', checked: false },
                // { value: 'false', label: 'Não Realizou', checked: false },
            ],
        },
    ],
})
  
const mutations = {
    SET_ENTIDADE: (state, payload) => {
        state.entidade = payload.entidade
    },
    SET_ENTIDADES: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.entidades = payload.itens
        }else{
            state.entidades = [...state.entidades, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_TOTAL_ENTIDADES: (state, payload) => {
        state.totalEntidades = payload
    },
    SET_LOADING_ENTIDADES: (state, payload) => {
        state.loadingEntidades = payload
    },
    SET_SHOW_MODAL_FILTER_ENTIDADE: (state, boolean) => {
        state.show_modal_filter_entidade = boolean
    },
    TOGGLE_FILTER_ENTIDADE_OPTION(state, { filterId, optionValue }) {
        const filter = state.filters_entidade.find(f => f.id === filterId);
        if (filter) {
            const option = filter.options.find(o => o.value === optionValue);
            if (option) {
            option.checked = !option.checked;
            }
        }
    },
}

const actions = {
    async fetchEntidade({commit}, id){
        try{
            commit('SET_LOADING_ENTIDADES', true)
            const response = await EntidadeService.getOne(id);
            commit('SET_ENTIDADE', response.data)
            commit('SET_LOADING_ENTIDADES', false)
        } catch (error) {
            commit('SET_LOADING_ENTIDADES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchEntidadeComplementar({commit}, cnpj){
        try{
            commit('SET_LOADING_ENTIDADES', true)
            const response = await EntidadeService.getComplementar(cnpj);
            if(response.data.valido) {
                if(response.data.entidade) commit('SET_DADOS_ENTIDADE_COMPLEMENTAR', response.data.entidade)
            }else{
                commit('SET_ALERT', {
                    heading: 'error',
                    message: response.data.message
                });
            }
            commit('SET_LOADING_ENTIDADES', false)
            return response.data
        } catch (error) {
            commit('SET_LOADING_ENTIDADES', false);
        }
    },
    async fetchEntidades({commit, state}, { keyword = '', pageNumber = 1, sortBy = 'data' }){
        try{
            let filters = state.filters_entidade.reduce((acc, filter) => {
                acc[filter.id] = filter.options.filter(option => option.checked).map(option => option.value);
                return acc;
            }, {});
            commit('SET_LOADING_ENTIDADES', true)
            const response = await EntidadeService.get(keyword, pageNumber, sortBy, filters);
            commit('SET_ENTIDADES', response.data)
            commit('SET_LOADING_ENTIDADES', false)
        } catch (error) {
            commit('SET_LOADING_ENTIDADES', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTotalEntidade({commit}){
        try{
            commit('SET_LOADING_ENTIDADES', true)
            const response = await EntidadeService.getTotal();
            commit('SET_TOTAL_ENTIDADES', response.data)
            commit('SET_LOADING_ENTIDADES', false)
        } catch (error) {
            commit('SET_LOADING_ENTIDADES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateEntidade({commit}, data){
        try{
            commit('SET_LOADING_ENTIDADES', true)
            const response = await EntidadeService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_ENTIDADES', false)
        } catch (error) {
            commit('SET_LOADING_ENTIDADES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    toggleFilterEntidadeOption({ commit, dispatch }, payload) {
        commit('TOGGLE_FILTER_ENTIDADE_OPTION', payload);
        dispatch('fetchEntidades', {keyword: "", pageNumber: 1})
    },
}

const getters = {
    getEntidade: state => state.entidade,
    getEntidades: state => state.entidades,
    getTotalEntidades: state => state.totalEntidades,
    getLoadingEntidades: state => state.loadingEntidades,
    getShowModalSearchEntidade: state => state.show_modal_filter_entidade,
    getFiltersEntidade: state => state.filters_entidade
}

export default {
    state,
    getters,
    mutations,
    actions
};