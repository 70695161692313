import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Dia Mundial do Rim',
    component: () => import('@/views/DiaMundialRimView.vue')
  },
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/confirm-account/:token',
    name: 'Confirm Account',
    component: () => import('@/views/ConfirmAcountView.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/DashboardView.vue')
  },
  {
    path: '/dashboardADM',
    name: 'DashboardADM',
    component: () => import('@/views/DashboardADMView.vue')
  },
  {
    path: '/register',
    name: 'Cadastro',
    component: () => import('@/views/RegisterView.vue')
  },
  {
    path: '/profile/settings',
    name: 'Configurações',
    component: () => import('@/views/SettingsEntidadeView.vue')
  },
  {
    path: '/form_entidade/:id_entidade',
    name: 'Editar Entidade',
    component: () => import('@/views/FormEntidadeView.vue'),
    meta: { title: 'Editar Entidade' }
  },
  {
    path: '/form_evento/:id_evento',
    name: 'Editar Evento',
    component: () => import('@/views/FormEventoView.vue'),
    meta: { title: 'Editar Evento' }
  },
  {
    path: '/profile/account',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/password-reset/:tokenPass?',
    name: 'Reset Password',
    component: () => import('@/views/PasswordResetView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
    
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
      return { x: 0, y: 0 };
    }
  },
})

router.beforeEach((to, from, next) => {
  document.title = `${to.name} | ${process.env.VUE_APP_TITLE}`
  window.scrollTo(0, 0) // Isso fará a rolagem para o topo da página
  next()
})

export default router
