import router from '../../router'
import PeriodService from '../../services/PeriodService'

const state = () => ({
    loadingPeriods: false,
    period: null,
    periods: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_period: false
})
  
const mutations = {
    SET_PERIOD: (state, payload) => {
        state.period = payload
    },
    SET_PERIODS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.periods = payload.items
        }else{
            state.periods = [...state.periods, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_PERIODS: (state, payload) => {
        state.loadingPeriod = payload
    },
    SET_SHOW_MODAL_PERIOD: (state, payload) => {
        state.show_modal_new_period = payload
    },
}

const actions = {
    async fetchPeriod({commit}, id){
        try{
            commit('SET_LOADING_PERIODS', true)
            const response = await PeriodService.getOne(id);
            commit('SET_PERIOD', response.data)
            commit('SET_LOADING_PERIODS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODS', false);
            if(error && error.response && error.response.data.message == "Sessão expirada") {
                commit('SET_REDIRECT', router.currentRoute.value.path);
            }
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPeriods({commit}, params = {}){
        try{
            commit('SET_LOADING_PERIODS', true)
            const { keyword = '', pageNumber = 1, pageSize = 12, order = { name: 'ID', column: 'ID', sort: 'ASC' } } = params;
            const response = await PeriodService.get(keyword, pageNumber, pageSize, order);
            commit('SET_PERIODS', response.data)
            commit('SET_LOADING_PERIODS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODS', false);
            if(error && error.response && error.response.data.message == "Sessão expirada") {
                commit('SET_REDIRECT', router.currentRoute.value.path);
            }
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createPeriod({commit}, data){
        try{
            commit('SET_LOADING_PERIODS', true)
            const response = await PeriodService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_PERIOD', false)
            commit('SET_LOADING_PERIODS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadePeriod({commit}, data){
        try{
            commit('SET_LOADING_PERIODS', true)
            const response = await PeriodService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_PERIODS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getPeriod: state => state.period,
    getPeriods: state => state.periods,
    getLoadingPeriod: state => state.loadingPeriod,
    getShowModalNewPeriod: state => state.show_modal_new_period
}

export default {
    state,
    getters,
    mutations,
    actions
};