import Api from './Api'

export default {
    get(keyword, pageNumber, sort, filters) {
        const params = new URLSearchParams();
        
        params.append('keyword', keyword);
        params.append('pageNumber', pageNumber);
        params.append('sort', sort);
        
        Object.keys(filters).forEach(key => {
            filters[key].forEach(value => {
                params.append(`${key}[]`, value);
            });
        });

        return Api().get(`entidades`, { params });
    },
    getTotal() {
        return Api().get(`entidades/total`);
    },
    getOne(id) {
        return Api().get(`entidades/${id}`);
    },
    getComplementar(cnpj) {
        return Api().post(`complementar`, {cnpj});
    },
    update(data){
        return Api().patch(`entidades/update/${data.id}`, data);
    },
}