// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import email from './modules/email';
import navbar from './modules/navbar';
import category from './modules/category';
import address from './modules/address';
import cropper_image from './modules/cropper_image';
import document from './modules/document';
import register from './modules/register';
import password from './modules/password';
import evento from './modules/evento';
import entidade from './modules/entidade';
import dada from './modules/data';
import wordpress from './modules/wordpress';
import pos_evento from './modules/pos-evento';
import image from './modules/image';
import periods from './modules/periods';

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        email,
        navbar,
        category,
        address,
        cropper_image,
        document,
        register,
        password,
        evento,
        entidade,
        dada,
        wordpress,
        pos_evento,
        image,
        periods
    }
})
